$background: #6b778d;
$header: #17223b;
$highlight: #c69c0d;

html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

html, body{
    height: 100%;
    font-family: 'Roboto Slab', sans-serif;
}

button, input[type=submit], .btn{
    background: $highlight;
    color: #fff;
    border: none;
    outline: none;
    padding: 0.5em 1em;
    border-radius: 3px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid rgba(0,0,0,0.2);

    &.btn-alt{
        background: #333;
        font-size: 0.85em;
    }

    &[disabled]{
        opacity: 0.5;
    }
    ~button, ~input[type=submit], ~.btn{
        margin-left: 0.5em;
    }
}

.App{
    max-width: 500px;
    margin: 0 auto;
}

.Game{
    padding: 0 2em;
}

h2{
    margin: 0.75em 0 0.25em;
    text-align: center;
}

.Toastify{
    .Toastify__toast{
        font-family: inherit;
        font-weight: 500;
        padding-left: 1em;
    }
}

.loading{
    display: flex;
    justify-content: center;
    padding: 4em;
}

.players{
    display: inline-flex;
    flex-direction: column;
    width: auto;
    width: 100%;

    .player{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 1em;
        padding-top: 1.5em;
        width: auto;
        min-width: 10em;
        font-weight: bold;
        border-top: 1px solid rgba(0,0,0,0.1);

        &:before{
            content: '';
            display: inline-block;
            position: absolute;
            left: -0.5em;
        }

        &:first-of-type{
            border-top: none;
            &:before{
                content: '🥇';
                font-size: 1.5em;
            }
        }
        &:nth-of-type(2){
            &:before{
                content: '🥈';
                font-size: 1.25em;
            }
        }
        &:nth-of-type(3){
            &:before{
                content: '🥉';
            }
        }

        &.-current{
            color: $highlight;
        }
        
        &:not(.-inactive)+.-inactive{
            margin-top: 2em;
            padding-top: 2em;
            border-top: 2px solid rgba(0,0,0,0.5);
        }

        &.-inactive{
            opacity: 0.5;

            &:before{
                content: '';
            }

            .increase, .decrease{
                visibility: hidden;
            }
        }

        .name{
            word-break: break-word;
            margin-right: 1.5em;

            +button{
                margin-right: 0.5em;
            }
        }

        .score{
            font-size: 1.25em;
        }

        .gm-actions{
            display: flex;
            flex-direction: column;
            margin-right: 1em;

            button{
                margin: 0;
                margin-bottom: 0.25em;
            }
        }

        >span:first-of-type{
            font-size: 1.2em;
            flex: 1;
        }
    }
}

.actions{
    text-align: center;
    padding: 2em 0;
}

.status{
    opacity: 0.75;
}

.header{
    width: 100%;
    text-align: center;
    padding: 0.5em 0;
    margin-bottom: 0.5em;

    img{
        max-width: 100%;
        width: 300px;
        padding: 0.5em;
        height: auto;
        margin-left: -0.75em;
    }
}

.toggle-gm-interface,
.celebrate{
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 0;
    margin: 0;
}

.celebrate{
    left: 0;
    right: auto;
}

.leave{
    margin-top: 2em;
}

.MainMenu{
    form{
        padding: 0 1em;
    }
    input{
        width: 100%;
        &:not([type=submit]){
            padding: 0.5em;
            border: 1px solid rgba(0,0,0,0.6);
            border-radius: 3px;
        }
        &[disabled]{
            opacity: 0.50;
        }
    }
}

.Lobby{
    display: flex;
    flex-direction: column;
    align-items: center;

    .make-gm{
        background: #eee;
        padding-right: 0.67em; // Fix for emoji not being centered
    }

    .toggle-active{
        background: #eee;
        padding-right: 0.78em; // Fix for emoji not being centered
    }
}